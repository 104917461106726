// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/Users/joeloliveira/Development/WWW/Projects/videophose/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("/Users/joeloliveira/Development/WWW/Projects/videophose/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/Users/joeloliveira/Development/WWW/Projects/videophose/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-form-js": () => import("/Users/joeloliveira/Development/WWW/Projects/videophose/src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-grid-example-js": () => import("/Users/joeloliveira/Development/WWW/Projects/videophose/src/pages/grid-example.js" /* webpackChunkName: "component---src-pages-grid-example-js" */),
  "component---src-pages-index-js": () => import("/Users/joeloliveira/Development/WWW/Projects/videophose/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/Users/joeloliveira/Development/WWW/Projects/videophose/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-some-dir-index-js": () => import("/Users/joeloliveira/Development/WWW/Projects/videophose/src/pages/some-dir/index.js" /* webpackChunkName: "component---src-pages-some-dir-index-js" */),
  "component---src-pages-some-dir-page-4-js": () => import("/Users/joeloliveira/Development/WWW/Projects/videophose/src/pages/some-dir/page-4.js" /* webpackChunkName: "component---src-pages-some-dir-page-4-js" */),
  "component---src-pages-page-3-mdx": () => import("/Users/joeloliveira/Development/WWW/Projects/videophose/src/pages/page-3.mdx" /* webpackChunkName: "component---src-pages-page-3-mdx" */)
}

