import PropTypes from "prop-types"
import React from "react"
import "./footer.scss"

const Footer = ({ siteTitle }) => (
    <>
        <footer className={`bg-primary`}>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer>
    </>
)

Footer.propTypes = {
    siteTitle: PropTypes.string,
}

Footer.defaultProps = {
    siteTitle: ``,
}

export default Footer
