import React, { Component } from 'react'
import {Form, FormControl, Button, Dropdown } from 'react-bootstrap'

// Search component
export default class Search extends Component {
    constructor(props) {
        super(props)
        this.state = {
            query: ``,
            results: [],
        }
    }

    render() {
        return (
            <Form inline onSubmit={this.handleSearch}>
                {this.state.results.length > 0 &&
                    <Dropdown>
                        <Dropdown.Menu show>
                            {this.state.results.map((page, index) => <Dropdown.Item key={index}>{page.title}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                }
                <FormControl type="text" placeholder="Search" className="mr-sm-2" onChange={this.handleInputChange} />
                <Button variant="outline-success" type="submit">Search</Button>
            </Form>
        )
    }

    getSearchResults(query) {
        if (!query || !window.__LUNR__) return []
        const lunrIndex =  window.__LUNR__[this.props.lang];
        const results = lunrIndex.index.search(query) // you can  customize your search , see https://lunrjs.com/guides/searching.html
        return results.map(({ ref }) => lunrIndex.store[ref])
    }

    handleInputChange = event => {
        const target = event.target
        const value = target.value

        this.setState({
            query: value,
        })
    }

    handleSearch = event => {
        event.preventDefault()
        const results = this.getSearchResults(this.state.query)
        console.log(results)
        this.setState(s => {
            return {
                results
            }
        })
    }
}