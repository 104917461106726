import { Navbar, Nav, Container } from 'react-bootstrap'
import PropTypes from "prop-types"
import React from "react"
//import { Link } from "gatsby"
import logo from "../images/gatsby-icon.png"
import { injectIntl, Link } from "gatsby-plugin-intl"
import Search from "../components/search"

const Header = ({ intl, siteTitle }) => (
  <>
    <Navbar collapseOnSelect expand="lg" fixed="top" bg="primary" variant="dark">
      <Container>
        <Link className={`navbar-brand`} to="/">
          <img
            alt=""
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Link className={`nav-link`} to="/page-2/">
                {intl.formatMessage({ id: "navigation.features" })}
            </Link>
            <Link className={`nav-link`} to="/page-3/">
              {intl.formatMessage({ id: "navigation.pricing" })}
            </Link>
            <Link className={`nav-link`} to="/some-dir/">Some Dir</Link>
            <Link className={`nav-link`} to="/blog/">
              {intl.formatMessage({ id: "navigation.blog" })}
            </Link>
            <Link className={`nav-link`} to="/form/">
              {intl.formatMessage({ id: "navigation.contacts" })}
            </Link>
          </Nav>
          <Search lang={intl.locale} />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default injectIntl(Header)
